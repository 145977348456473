import { ComponentProps, ElementType, forwardRef, ReactNode, Ref } from 'react'

type OwnProps<Element extends ElementType = ElementType> = {
  as?: Element
}

type Props<Element extends ElementType> = OwnProps<Element> &
  Omit<ComponentProps<Element>, keyof OwnProps>

const defaultElement = 'div'

export const BaseInteractive: <
  Element extends ElementType = typeof defaultElement,
>(
  props: Props<Element>
) => ReactNode = forwardRef(function InnerComponent(
  props: OwnProps,
  ref: Ref<unknown>
) {
  const { as, ...other } = props
  const Element = as || defaultElement

  return <Element {...other} ref={ref} />
})
