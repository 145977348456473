import clsx from 'clsx'
import {
  forwardRef,
  ReactElement,
  Ref,
  ComponentProps,
  ElementType,
  ReactNode,
} from 'react'

import { BaseInteractive } from '@/ui/core/BaseInteractive'

import styles from './BaseButton.module.css'

type Color = 'black' | 'gray' | 'ghost' | 'white' | 'clear'

type Size = 'none' | 'xxs' | 'xs' | 'xs-equal' | 's' | 'm'

type HorizontalLayout = 'default' | 'center' | 'strech'

type OwnProps<
  Element extends ElementType =
    | ElementType<'a'>
    | ElementType<'button'>
    | ElementType,
> = {
  as: Element
  color: Color
  size: Size
  layout?: HorizontalLayout
  round?: boolean
  fullWidth?: boolean
  iconLeft?: ReactElement
  children?: ReactNode
  iconRight?: ReactElement
  className?: string
}

type Props<Element extends ElementType> = OwnProps<Element> &
  Omit<ComponentProps<Element>, keyof OwnProps>

const colorClasses: Record<Color, string> = {
  black: styles.colorBlack,
  gray: styles.colorGray,
  ghost: styles.colorGhost,
  white: styles.colorWhite,
  clear: styles.colorClear,
}

const sizeClasses: Record<Size, string> = {
  none: styles.sizeNone,
  xxs: styles.sizeXXS,
  xs: styles.sizeXS,
  'xs-equal': styles.sizeXSEqual,
  s: styles.sizeS,
  m: styles.sizeM,
}

const layoutClasses: Record<HorizontalLayout, string> = {
  default: styles.layoutDefault,
  center: styles.layoutCenter,
  strech: styles.layoutStrech,
}

export const BaseButton: <Element extends ElementType = 'a' | 'button'>(
  props: Props<Element>
) => ReactNode = forwardRef(function InnerComponent(
  props: OwnProps,
  ref: Ref<unknown>
) {
  const {
    as,
    color,
    size,
    layout,
    round,
    fullWidth,
    iconLeft,
    children,
    iconRight,
    className,
    ...other
  } = props

  return (
    <BaseInteractive
      {...other}
      as={as}
      ref={ref}
      className={clsx(
        styles.root,
        colorClasses[color],
        sizeClasses[size],
        layoutClasses[layout || 'default'],
        round && styles.round,
        fullWidth && styles.fullWidth,
        iconLeft && styles.iconLeft,
        iconRight && styles.iconRight,
        className
      )}
    >
      {iconLeft && (
        <span className={clsx(styles.icon, styles.iconLeft)}>{iconLeft}</span>
      )}
      {children}
      {iconRight && (
        <span className={clsx(styles.icon, styles.iconRight)}>{iconRight}</span>
      )}
    </BaseInteractive>
  )
})
