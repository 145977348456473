import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from '@/contexts'
import { useReportId } from '@/contexts/reportId'
import { useCompletedOrdersQuery } from '@/queries/order'
import { Layout } from '@/ui/components/Layout'
import { UserIcon, InnerLink, AddIcon } from '@/ui/core'

import styles from './MainLayout.module.css'

type Props = {
  size: 'xs' | 's'
  showFooter?: boolean
}

export const MainLayout: FC<Props> = ({ size = 'xs', showFooter = true }) => {
  const { user } = useAuth()
  const [reportId] = useReportId()
  const ordersQuery = useCompletedOrdersQuery({
    query: {
      sort: 'createdAt:desc',
    },
    enabled: user !== null,
  })

  const isSomeComplete =
    ordersQuery.data !== undefined && ordersQuery.data.length > 0

  return (
    <Layout size={size}>
      <Layout.Header>
        <div>
          {user && isSomeComplete && (
            <InnerLink
              className={styles.menu}
              to={`/add?reportId=${reportId}`}
              color="clear"
              size="none"
            >
              <AddIcon />
            </InnerLink>
          )}
          <InnerLink
            to={user ? '/profile' : '/auth'}
            className={styles.menu}
            color="clear"
            size="none"
          >
            <span>
              <UserIcon />
            </span>
            <span className={styles.name}>
              {user ? user.firstName : 'Войти'}
            </span>
          </InnerLink>
        </div>
      </Layout.Header>
      <main>
        <Outlet />
      </main>
      {showFooter && <Layout.Footer />}
    </Layout>
  )
}
