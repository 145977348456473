const DEFAULT_VALUE = {}

export class Storage<Value = Record<string, unknown>> {
  private state: Partial<Value> = DEFAULT_VALUE

  get<Key extends keyof Value>(key: Key): Value[Key] | undefined {
    return this.state[key]
  }

  getState() {
    return this.state
  }

  set<Key extends keyof Value>(key: Key, value: Value[Key]) {
    this.state[key] = value
  }

  setState(value: Value) {
    this.state = value
  }

  reset() {
    this.state = DEFAULT_VALUE
  }
}
