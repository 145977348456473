import qs from 'qs'

import { authHttp } from '@/services'

import {
  CreateOrderRequest,
  OrderFindRequest,
  OrderResponse,
  OrderStatus,
  OrdersResponse,
  UpdateOrderRequest,
} from './types'

export const orderService = {
  async getList(params: OrderFindRequest = {}) {
    const query = qs.stringify(params)
    const { data } = await authHttp.get<OrdersResponse>(`/order?${query}`)

    return data
  },

  async getById(id: number) {
    const { data } = await authHttp.get<OrderResponse>(`/order/${id}`)

    return data
  },

  async getLast(status?: OrderStatus) {
    const list = await this.getList({
      filters: {
        status,
      },
      sort: 'createdAt:desc',
      limit: 1,
    })

    return list[0] || null
  },

  async create(request: CreateOrderRequest) {
    const { data } = await authHttp.post<OrderResponse>('/order', request)

    return data
  },

  async update(request: UpdateOrderRequest) {
    const { data } = await authHttp.post<OrderResponse>(
      `/order/${request.id}`,
      request.data
    )

    return data
  },

  async download(id: string | number) {
    const { data, headers } = await authHttp.get<Blob>(
      `/order/${id}/report/download`,
      {
        responseType: 'blob',
      }
    )
    const name =
      (headers['content-type'] === 'application/pdf' && 'report.pdf') ||
      (headers['content-type'] === 'application/zip' && 'reports.zip') ||
      'unknown.txt'

    return { name, blob: data }
  },
}
