export const parse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch {
    return null
  }
}

export const stringify = <Value extends object>(value: Value) => {
  try {
    return JSON.stringify(value)
  } catch {
    return null
  }
}
