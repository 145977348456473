import clsx from 'clsx'
import { OptionHTMLAttributes, SelectHTMLAttributes, forwardRef } from 'react'

import { ArrowIcon } from './Icons'
import styles from './Select.module.css'

type Size = 's' | 'm'

type RootProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> & {
  rounded?: boolean
  size?: Size
  error?: boolean
}

const Root = forwardRef<HTMLSelectElement, RootProps>(
  ({ className, error, size = 'm', rounded = false, ...props }, ref) => {
    return (
      <div className={clsx(styles.root, error && styles.error, className)}>
        <select
          {...props}
          ref={ref}
          className={clsx(styles.input, rounded && styles.rounded)}
          data-size={size}
        />
        <ArrowIcon className={styles.icon} />
      </div>
    )
  }
)

Root.displayName = 'Select'

type ItemProps = OptionHTMLAttributes<HTMLOptionElement>

const Item = forwardRef<HTMLOptionElement, ItemProps>(
  ({ className, ...props }, ref) => {
    return <option {...props} ref={ref} className={className} />
  }
)

Item.displayName = 'SelectItem'

export const Select = {
  Root,
  Item,
}
