import { AnchorHTMLAttributes, ReactElement, forwardRef } from 'react'

import { BaseButton } from '@/ui/core/BaseButton'

type Color = 'black' | 'gray' | 'white' | 'clear'

type Size = 'none' | 'xs' | 's' | 'm'

type Layout = 'default' | 'center' | 'strech'

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  color: Color
  size: Size
  layout?: Layout
  iconLeft?: ReactElement
  iconRight?: ReactElement
  round?: boolean
  fullWidth?: boolean
}

export const OuterLink = forwardRef<HTMLAnchorElement, Props>(
  function OuterLink(props, ref) {
    const { color, size, layout, ...other } = props

    return (
      <BaseButton
        {...other}
        ref={ref}
        as="a"
        color={color}
        size={size}
        layout={layout}
      />
    )
  }
)
