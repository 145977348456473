import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import type { ID } from '@/api/types'

type State = [ID | undefined, (value: ID | undefined) => void]

const noop = () => undefined

export const ReportIdContext = createContext<State>([undefined, noop])

type Props = {
  children?: ReactNode
}

export const ReportIdContextProvider: FC<Props> = ({ children }) => {
  const state = useState<ID | undefined>(undefined)
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    if (!params.has('reportId')) {
      return
    }

    const [, setState] = state
    const id = params.get('reportId')
    if (!id) {
      return
    }

    setState(id)
  }, [params, setParams])

  return (
    <ReportIdContext.Provider value={state}>
      {children}
    </ReportIdContext.Provider>
  )
}

export const useReportId = () => useContext(ReportIdContext)
