type Callback = () => void

type Listener = {
  id: number
  callback: Callback
}

const AuthSync = () => {
  let listeners: Listener[] = []

  return {
    subscribe: (callback: Callback) => {
      const last = listeners[listeners.length - 1]
      const id = last ? last.id + 1 : 0

      listeners.push({
        id,
        callback,
      })

      return listeners.length
    },

    unsubscribe: (listenerId: number) => {
      listeners = listeners.filter(({ id }) => id !== listenerId)
    },

    logout: () => {
      listeners.map(({ callback }) => callback())
    },
  }
}

export const authSync = AuthSync()
