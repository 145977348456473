type Map<Value> = {
  apply: <Result>(callback: (value: Value) => Result) => Map<Result>
  value: () => Value
}

export const map = <Value>(value: Value): Map<Value> => {
  return {
    apply: (callback) => map(callback(value)),
    value: () => value,
  }
}
