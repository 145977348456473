import clsx from 'clsx'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './PaperTitle.module.css'

type Props = HTMLAttributes<HTMLParagraphElement> & {
  children?: ReactNode
  className?: string
}

export const PaperDescription = forwardRef<HTMLParagraphElement, Props>(
  (props, ref) => {
    const { children, className, ...other } = props

    return (
      <p {...other} ref={ref} className={clsx(styles.root, className)}>
        {children}
      </p>
    )
  }
)

PaperDescription.displayName = 'PaperTitle'
