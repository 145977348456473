import type { ApiErrorResponse } from './types'

export const isApiErrorResponse = (
  value: unknown
): value is ApiErrorResponse => {
  return (
    value !== undefined &&
    value !== null &&
    Object.prototype.hasOwnProperty.call(value, 'error')
  )
}
