import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App'
import './utils/sentry'

import './styles/vars.css'
import './styles/fonts.css'
import './styles/base.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
