import type { FC, ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/contexts'

type Props = {
  children: ReactElement
}

export const RequireAuth: FC<Props> = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.status === 'pending') {
    return null
  }

  if (!auth.user) {
    return <Navigate to="/auth" state={{ from: location }} replace />
  }

  return children
}
