import { ComponentProps, ReactElement, forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { BaseButton } from '@/ui/core/BaseButton'

type Color = 'black' | 'gray' | 'white' | 'clear'

type Size = 'none' | 'xs' | 's' | 'm'

type Layout = 'default' | 'center' | 'strech'

type Props = ComponentProps<typeof Link> & {
  color: Color
  size: Size
  layout?: Layout
  iconLeft?: ReactElement
  iconRight?: ReactElement
  round?: boolean
  fullWidth?: boolean
}

export const InnerLink = forwardRef<HTMLAnchorElement, Props>(
  function InnerLink(props, ref) {
    const { color, size, layout, ...other } = props

    return (
      <BaseButton
        {...other}
        ref={ref}
        as={Link}
        color={color}
        size={size}
        layout={layout}
      />
    )
  }
)
