import clsx from 'clsx'
import type { FC, HTMLAttributes, PropsWithChildren } from 'react'

import logoImg from '@/assets/images/logo.svg'
import logoWhiteImg from '@/assets/images/logo_white.svg'
import { PAGES } from '@/pages/router'
import { VkIcon, InstaIcon, TelegramIcon, InnerLink } from '@/ui/core'

import styles from './Layout.module.css'

const Header = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <header className={styles.header}>
      <InnerLink to={PAGES.MAIN.PATH()} color="clear" size="none">
        <img src={logoImg} alt="Рельса" width={122} height={32} />
      </InnerLink>

      {children}
    </header>
  )
}

type FooterProps = {
  className?: string
}

const Footer: FC<FooterProps> = ({ className }) => {
  return (
    <footer className={clsx(styles.footer, className)}>
      <div className={styles.footerMain}>
        <InnerLink to={PAGES.REPORT.PATH()} color="clear" size="none">
          <img src={logoWhiteImg} alt="Рельса" width={122} height={32} />
        </InnerLink>

        <div className={styles.socials}>
          <a
            className={styles.social}
            href="https://vk.com/relsaru"
            target="_blank"
            rel="noreferrer noopener"
          >
            <VkIcon />
          </a>
          <a
            className={styles.social}
            href="https://t.me/relsa_ru"
            target="_blank"
            rel="noreferrer noopener"
          >
            <TelegramIcon />
          </a>
          <a
            className={styles.social}
            href="https://www.instagram.com/relsa_ru/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <InstaIcon />
          </a>
        </div>
      </div>
      <p className={styles.disclaimer}>
        Вся информация носит информационный характер и не даёт инструкций к
        действию. Ставить диагноз и назначать лечение может только врач при
        очной встрече. Ознакомьтесь с{' '}
        <a className={styles.link} href="/docs/offer.pdf" target="_blank">
          офертой
        </a>{' '}
        и{' '}
        <a
          className={styles.link}
          href="/docs/privacy-policy.pdf"
          target="_blank"
        >
          политикой конфиденциальности
        </a>
        .
      </p>
    </footer>
  )
}

type LayoutProps = HTMLAttributes<HTMLDivElement> & {
  size: 'xs' | 's'
}

type LayoutComponent = {
  (props: LayoutProps): JSX.Element
  Header: typeof Header
  Footer: typeof Footer
}

const sizeClasses: Record<'xs' | 's', string> = {
  xs: styles.sizeXs,
  s: styles.sizeS,
}

export const Layout: LayoutComponent = ({
  size,
  children,
  className,
}: LayoutProps) => {
  return (
    <div className={clsx(styles.root, sizeClasses[size], className)}>
      {children}
    </div>
  )
}

Layout.Header = Header
Layout.Footer = Footer
