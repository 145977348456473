import { ButtonHTMLAttributes, ReactElement, forwardRef } from 'react'

import { BaseButton } from '@/ui/core/BaseButton'

type Color = 'black' | 'gray' | 'ghost' | 'white' | 'clear'

type Size = 'none' | 'xxs' | 'xs' | 'xs-equal' | 's' | 'm'

type Layout = 'default' | 'center' | 'strech'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  color: Color
  size: Size
  layout?: Layout
  iconLeft?: ReactElement
  iconRight?: ReactElement
  round?: boolean
  fullWidth?: boolean
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  function Button(props, ref) {
    const { color, size, layout, ...other } = props

    return (
      <BaseButton
        {...other}
        ref={ref}
        as="button"
        color={color}
        size={size}
        layout={layout}
      />
    )
  }
)
