import { AxiosError, AxiosInstance } from 'axios'

import { authSync } from '@/utils/auth'

import {
  ApiApplicationError,
  ApiError,
  ApiNotFoundError,
  ApiValidationError,
} from './error'
import { isApiErrorResponse } from './helpers'

export const withHandleApiError = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError | unknown) => {
      if (error instanceof AxiosError && error.response?.data !== undefined) {
        const data = error.response.data

        if (isApiErrorResponse(data)) {
          if (data.error.name === 'ApplicationError') {
            throw new ApiApplicationError(
              data.error.message,
              data.error.details
            )
          }

          if (data.error.name === 'NotFoundError') {
            throw new ApiNotFoundError(data.error.message, data.error.details)
          }

          if (data.error.name === 'ValidationError') {
            throw new ApiValidationError(data.error.message, data.error.details)
          }

          if (data.error.name === 'UnauthorizedError') {
            authSync.logout()
          }

          throw new ApiError(
            data.error.name,
            data.error.status,
            data.error.message,
            data.error.details
          )
        }
      }

      throw error
    }
  )

  return instance
}

export const withAuth = (
  instance: AxiosInstance,
  getToken: () => string | undefined
) => {
  instance.interceptors.request.use((config) => {
    const token = getToken()
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`)
    }

    return config
  })

  return instance
}
