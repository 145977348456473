import { parse, stringify } from '@/utils/json'

import { Storage } from './storage'

export const withPersist = <Data extends Record<string, unknown>>(
  storage: Storage<Data>,
  storageName: string
): Storage<Data> => {
  const data = window.localStorage.getItem(storageName)
  if (data !== null) {
    storage.setState(parse(data) || {})
  }

  storage.set = (key, value) => {
    Storage.prototype.set.apply(storage, [key, value])

    const state = stringify(storage.getState())
    if (state) {
      window.localStorage.setItem(storageName, state)
    } else {
      window.localStorage.removeItem(storageName)
    }
  }

  storage.setState = (value) => {
    Storage.prototype.setState.apply(storage, [value])

    const state = stringify(value)
    if (state) {
      window.localStorage.setItem(storageName, state)
    } else {
      window.localStorage.removeItem(storageName)
    }
  }

  storage.reset = () => {
    Storage.prototype.reset.apply(storage)

    window.localStorage.removeItem(storageName)
  }

  window.addEventListener('storage', (event) => {
    if (event.key !== storageName) {
      return
    }

    const state = (event.newValue !== null && parse(event.newValue)) || null
    if (state !== null) {
      storage.setState(state)
    }
  })

  return storage
}
