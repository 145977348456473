import type { ApiErrorName, ApiErrorStatus, Details } from './types'

const DEFAULT_DETAILS: Details = {
  errors: [],
}

export class ApiError extends Error {
  constructor(
    public name: ApiErrorName,
    public status: ApiErrorStatus<typeof name>,
    public message: string,
    public details: Details = DEFAULT_DETAILS
  ) {
    super(message)
  }
}

export class ApiApplicationError extends ApiError {
  constructor(message?: string, details?: Details) {
    super('ApplicationError', 400, message ?? 'ApplicationError', details)
  }
}

export class ApiValidationError extends ApiError {
  constructor(message?: string, details?: Details) {
    super('ValidationError', 400, message ?? 'ValidationError', details)
  }
}

export class ApiNotFoundError extends ApiError {
  constructor(message?: string, details?: Details) {
    super('NotFoundError', 404, message ?? 'NotFoundError', details)
  }
}
