import { useQueryClient } from '@tanstack/react-query'
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { AuthService } from '@/api/auth'
import { SignInByCodeResponse } from '@/api/auth/types'
import { AuthStorage } from '@/services'
import { User } from '@/services/storage/auth'
import { authSync } from '@/utils/auth'

type Status = 'pending' | 'completed'

type State = {
  status: Status
  user: User | null
  set: (data: SignInByCodeResponse) => Promise<void>
  setUser: (data: User) => Promise<void>
  login: (code: string) => Promise<void>
  logout: () => Promise<void>
}

const DEFAULT_STATE: State = {
  status: 'pending',
  user: null,
  set: () => Promise.resolve(),
  setUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
}

export const AuthContext = createContext<State>(DEFAULT_STATE)

type Props = {
  children?: ReactNode
}

export const AuthContextProvider: FC<Props> = ({ children }) => {
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const queryClient = useQueryClient()

  const set = useCallback(async (data: SignInByCodeResponse) => {
    setState((state) => ({
      ...state,
      user: data.user,
      status: 'completed',
    }))

    AuthStorage.setState(data)
  }, [])

  const setUser = useCallback(async (data: User) => {
    setState((state) => ({
      ...state,
      user: data,
    }))

    const user = AuthStorage.get('user')
    AuthStorage.set('user', { ...user, ...data })
  }, [])

  const login = useCallback(
    async (code: string) => {
      const data = await AuthService.requestAuthorizationByCode({
        code,
      })

      set(data)
    },
    [set]
  )

  const logout = useCallback(async () => {
    AuthStorage.reset()

    setState({
      ...state,
      user: null,
    })
    queryClient.clear()
  }, [state])

  const contextState = useMemo<State>(
    () => ({
      ...state,
      set,
      setUser,
      login,
      logout,
    }),
    [state, set, setUser, login, logout]
  )

  useEffect(() => {
    const id = authSync.subscribe(logout)

    return () => {
      authSync.unsubscribe(id)
    }
  }, [logout])

  useLayoutEffect(() => {
    const user = AuthStorage.get('user')

    if (user !== undefined) {
      setState((state) => ({
        ...state,
        user: user || state.user,
      }))
    }

    setState((state) => ({
      ...state,
      status: 'completed',
    }))
  }, [])

  return (
    <AuthContext.Provider value={contextState}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
