import { useEffect, type FC, type ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

type Props = {
  children?: ReactElement
}

export const Analytic: FC<Props> = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.ym?.(import.meta.env.VITE_YANDEX_METRIKA_COUNTER_ID, 'init', {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    })
  }, [])

  useEffect(() => {
    window.ym?.(
      import.meta.env.VITE_YANDEX_METRIKA_COUNTER_ID,
      'hit',
      `${window.location.origin}${window.location.pathname}`
    )
  }, [pathname])

  return children || null
}
