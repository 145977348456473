import clsx from 'clsx'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import styles from './PaperTitle.module.css'

type Props = HTMLAttributes<HTMLHeadingElement> & {
  /**
   * @default "h1"
   */
  as?: `h${1 | 2 | 3 | 4 | 5 | 6}`
  children?: ReactNode
  className?: string
}

export const PaperTitle = forwardRef<HTMLHeadingElement, Props>(
  (props, ref) => {
    const { as: Element = 'h1', children, className, ...other } = props

    return (
      <Element
        {...other}
        ref={ref}
        className={clsx(styles.root, styles[Element], className)}
      >
        {children}
      </Element>
    )
  }
)

PaperTitle.displayName = 'PaperTitle'
