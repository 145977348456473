import qs from 'qs'

import { AuthStorage } from '@/services/storage'
import { map } from '@/utils/map'

import { withAuth, withHandleApiError } from './decorators'
import { Config, createHttpClient } from './http'

const BASE_CONFIG: Config = {
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: window.location.host.includes('relsa.ru'),
  paramsSerializer: (params) => {
    return qs.stringify(params)
  },
}

export const publicHttp = map(BASE_CONFIG)
  .apply(createHttpClient)
  .apply(withHandleApiError)
  .value()

export const authHttp = map(BASE_CONFIG)
  .apply(createHttpClient)
  .apply(withHandleApiError)
  .apply((instance) => {
    return withAuth(instance, () => AuthStorage.get('jwt'))
  })
  .value()
