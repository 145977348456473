import { useQuery } from '@tanstack/react-query'

import { orderService } from '@/api/order'
import { OrderStatus } from '@/api/order/types'

export const ordersKeys = {
  order: (id?: string | number) => ['order', String(id)] as const,
  orders: (limit?: number, sort?: `createdAt:${'asc' | 'desc'}`) =>
    [
      'orders',
      {
        ...(limit ? { limit } : {}),
        ...(sort ? { sort } : {}),
      },
    ] as const,
  completed: (limit?: number, sort?: `createdAt:${'asc' | 'desc'}`) =>
    [
      'orders',
      {
        status: 'completed',
        ...(limit ? { limit } : {}),
        ...(sort ? { sort } : {}),
      },
    ] as const,
  notCompleted: (status?: OrderStatus[]) =>
    [
      'orders',
      {
        status,
      },
    ] as const,
}

type OrderQueryParams = {
  query: Partial<{
    id: number
  }>
  enabled?: boolean
}

export const useOrderQuery = ({ query, enabled }: OrderQueryParams) => {
  return useQuery({
    queryKey: ordersKeys.order(query.id),
    queryFn: () => {
      if (query.id === undefined) {
        throw new Error('Query param "id" is not defined')
      }

      return orderService.getById(query.id)
    },
    enabled,
  })
}

type OrdersQueryParams = {
  query: Partial<{
    limit: number
    sort: `createdAt:${'asc' | 'desc'}`
  }>
  enabled?: boolean
}

export const useOrdersQuery = ({ query, enabled }: OrdersQueryParams) => {
  return useQuery({
    queryKey: ordersKeys.orders(query.limit, query.sort),
    queryFn: async () => {
      return orderService.getList({
        sort: query.sort,
        limit: query.limit,
      })
    },
    enabled,
  })
}

type CompletedOrdersQueryParams = {
  query: Partial<{
    limit: number
    sort: `createdAt:${'asc' | 'desc'}`
  }>
  enabled?: boolean
}

export const useCompletedOrdersQuery = ({
  query,
  enabled,
}: CompletedOrdersQueryParams) => {
  return useQuery({
    queryKey: ordersKeys.completed(query.limit, query.sort),
    queryFn: async () => {
      return orderService.getList({
        filters: {
          status: {
            $in: ['completed', 'commented'],
          },
        },
        sort: query.sort,
        limit: query.limit,
      })
    },
    enabled,
  })
}

type NotCompletedOrdersQueryParams = {
  variables: {
    status?: OrderStatus[]
  }
  enabled?: boolean
}

const DEFAULT_NOT_COMPLETED_STATUS: OrderStatus[] = [
  'cancelled',
  'confirmed',
  'created',
  'partial-completed',
  'waiting',
]

export const useNotCompletedOrderQuery = (
  { variables, enabled }: NotCompletedOrdersQueryParams = { variables: {} }
) => {
  return useQuery({
    queryKey: ordersKeys.notCompleted(
      variables.status ?? DEFAULT_NOT_COMPLETED_STATUS
    ),
    queryFn: async () => {
      const orders = await orderService.getList({
        filters: {
          status: {
            $in: variables.status ?? DEFAULT_NOT_COMPLETED_STATUS,
          },
        },
        sort: 'createdAt:desc',
        limit: 1,
      })

      return orders.length > 0 ? orders[0] : null
    },
    enabled,
  })
}
