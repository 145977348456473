import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

type State = number

const DEFAULT_STATE: State = 0

export const HistoryLengthContext = createContext<State>(DEFAULT_STATE)

type Props = {
  children?: ReactNode
}

export const HistoryLengthContextProvider: FC<Props> = ({ children }) => {
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const location = useLocation()
  const prevPath = useRef<string | null>(null)

  useEffect(() => {
    if (prevPath.current !== null && location.pathname !== prevPath.current) {
      setState((state) => state + 1)
    }

    prevPath.current = location.pathname
  }, [location.pathname])

  return (
    <HistoryLengthContext.Provider value={state}>
      {children}
    </HistoryLengthContext.Provider>
  )
}

export const useHistoryLengthContext = () => useContext(HistoryLengthContext)
