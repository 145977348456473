import cx from 'clsx'
import { ReactNode, forwardRef } from 'react'

import styles from './Paper.module.css'

type Props = {
  className?: string
  children?: ReactNode
}

export const Paper = forwardRef<HTMLDivElement, Props>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={cx(styles.root, className)}>
        {children}
      </div>
    )
  }
)

Paper.displayName = 'Paper'
