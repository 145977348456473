import type { FC, ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/contexts'
import { PAGES } from '@/pages/router'

type Props = {
  children: ReactElement
}

export const RequireGuest: FC<Props> = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.status === 'pending') {
    return null
  }

  if (auth.user !== null) {
    return (
      <Navigate to={PAGES.REPORT.PATH()} state={{ from: location }} replace />
    )
  }

  return children
}
