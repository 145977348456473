import { map } from '@/utils/map'

import { withPersist } from './decorators'
import { Storage } from './storage'

export type User = {
  id: number
  firstName: string
  middleName: string
  lastName: string
  birthDate: string
  email: string
  phone: string
  gender: 'male' | 'female'
}

type AuthState = {
  jwt: string
  user: User
}

export const AuthStorage = map(new Storage<AuthState>())
  .apply((storage) => withPersist(storage, '@auth'))
  .value()
