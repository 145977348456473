export type PayParams = {
  description?: string
  amount: number
  invoiceId: string
  email: string
  tariff?: {
    name: string
    vat: number
    method: string
    object: string
    measurementUnit: string
  }
  payer: Payer
}

export class Payment {
  constructor(private instance: CloudPayments) {}

  pay(
    { email, description, tariff, payer, ...params }: PayParams,
    events?: CloudPaymentsPayEvents
  ) {
    return this.instance.pay(
      'auth',
      {
        ...params,
        publicId: import.meta.env.VITE_CLOUD_PAYMENTS_PUBLIC_ID,
        description: description ? description : 'Оплата тарифа',
        currency: 'RUB',
        accountId: email,
        email,
        skin: 'classic',
        requireEmail: false,
        data: {
          CloudPayments: {
            CustomerReceipt: {
              Items: [
                {
                  label: tariff ? tariff.name : 'Тариф',
                  price: params.amount,
                  quantity: 1.0,
                  amount: params.amount,
                  vat: tariff?.vat || 0,
                  method: tariff ? Number(tariff.method) : undefined,
                  object: tariff ? Number(tariff.object) : undefined,
                  measurementUnit: tariff?.measurementUnit,
                },
              ],
              Email: email,
              CustomerBirthday: payer.birth,
              Amounts: {
                electronic: params.amount,
                advancePayment: 0.0,
                credit: 0.0,
                provision: 0.0,
              },
            },
          },
        },
        payer,
      },
      events
    )
  }
}

export const PaymentInstance = new Payment(
  new window.cp.CloudPayments({ language: 'ru-RU' })
)
