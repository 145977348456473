import {
  Outlet,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom'

import { AuthContextProvider } from '@/contexts'
import { HistoryLengthContextProvider } from '@/contexts/history'
import { ReportIdContextProvider } from '@/contexts/reportId'
import { Analytic } from '@/ui/components/Analytic'
import { RequireAuth } from '@/ui/components/RequireAuth'
import { RequireGuest } from '@/ui/components/RequireGuest'
import { MainLayout } from '@/ui/layouts'

export const PAGES = {
  MAIN: {
    PATH: () => '/',
  },
  AUTH: {
    PATH: () => '/auth',
  },
  AUTH_VERIFICATION: {
    PATH: () => '/auth/verification',
  },
  REPORT: {
    PATH: () => `/report`,
  },
  REPORT_BY_ID: {
    PATH: ({ reportId }: Record<'reportId', string>) => `/report/${reportId}`,
  },
  REPORT_AGE: {
    PATH: ({ reportId }: Record<'reportId', string>) =>
      `/report/${reportId}/age`,
  },
  REPORT_SYSTEM: {
    PATH: ({ reportId, entityId }: Record<'reportId' | 'entityId', string>) =>
      `/report/${reportId}/system/${entityId}`,
  },
  REPORT_SPHERE: {
    PATH: ({ reportId, entityId }: Record<'reportId' | 'entityId', string>) =>
      `/report/${reportId}/sphere/${entityId}`,
  },
  REPORT_MARKER: {
    PATH: ({ reportId, markerId }: { reportId?: string; markerId: string }) =>
      `/report/marker/${markerId}${reportId ? `?reportId=${reportId}` : ''}`,
  },
  RECOMMENDATION: {
    PATH: ({
      reportId,
      recommendationId,
    }: {
      reportId: string
      recommendationId: string
    }) => `/report/${reportId}/recommendation/${recommendationId}`,
  },
  CHECKOUT: {
    PATH: (tariffId?: number) =>
      `/checkout${tariffId ? `?tariffId=${tariffId}` : ''}`,
  },
  LK: {
    PATH: () => '/profile',
  },
  ADD: {
    PATH: () => '/add',
  },
  SURVEY: {
    PATH: (id: string | number) => `/survey/${id}`,
  },
}

export const router = createBrowserRouter([
  {
    element: (
      <Analytic>
        <AuthContextProvider>
          <ReportIdContextProvider>
            <HistoryLengthContextProvider>
              <Outlet />
            </HistoryLengthContextProvider>
            <ScrollRestoration />
          </ReportIdContextProvider>
        </AuthContextProvider>
      </Analytic>
    ),
    children: [
      {
        element: (
          <RequireGuest>
            <Outlet />
          </RequireGuest>
        ),
        children: [
          {
            path: PAGES.MAIN.PATH(),
            lazy: () => import('./Main'),
          },
        ],
      },
      {
        element: (
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        ),
        children: [
          {
            path: PAGES.SURVEY.PATH(':id'),
            lazy: () => import('./Survey'),
          },
        ],
      },
      {
        element: (
          <RequireGuest>
            <Outlet />
          </RequireGuest>
        ),
        children: [
          {
            path: PAGES.AUTH.PATH(),
            lazy: () => import('./Auth'),
          },
          {
            path: PAGES.AUTH_VERIFICATION.PATH(),
            lazy: () => import('./AuthVerification'),
          },
        ],
      },
      {
        element: (
          <RequireAuth>
            <MainLayout size="xs" />
          </RequireAuth>
        ),
        children: [
          {
            path: PAGES.REPORT.PATH(),
            lazy: () => import('./Report'),
          },
          {
            path: PAGES.REPORT_BY_ID.PATH({ reportId: ':reportId' }),
            lazy: () => import('./Report'),
          },
          {
            path: PAGES.REPORT_AGE.PATH({ reportId: ':reportId' }),
            lazy: () => import('./ReportAge'),
          },
          {
            path: PAGES.REPORT_MARKER.PATH({
              markerId: ':markerId',
            }),
            lazy: () => import('./ReportMarker'),
          },
          {
            path: PAGES.REPORT_SYSTEM.PATH({
              reportId: ':reportId',
              entityId: ':entityId',
            }),
            lazy: () => import('./ReportSystem'),
          },
          {
            path: PAGES.REPORT_SPHERE.PATH({
              reportId: ':reportId',
              entityId: ':entityId',
            }),
            lazy: () => import('./ReportSystem'),
          },
          {
            path: PAGES.RECOMMENDATION.PATH({
              reportId: ':reportId',
              recommendationId: ':recommendationId',
            }),
            lazy: () => import('./Recommendation'),
          },
          {
            path: PAGES.LK.PATH(),
            lazy: () => import('./Profile'),
          },
          {
            path: PAGES.ADD.PATH(),
            lazy: () => import('./Add'),
          },
        ],
      },
      {
        element: <MainLayout size="s" showFooter={false} />,
        children: [
          {
            path: PAGES.CHECKOUT.PATH(),
            lazy: () => import('./Checkout'),
          },
        ],
      },
      {
        element: <MainLayout size="xs" />,
        children: [
          {
            path: '*',
            lazy: async () => {
              const { NotFound } = await import('./NotFound')
              return { Component: NotFound }
            },
          },
        ],
      },
    ],
  },
])
