import { authHttp, publicHttp } from '@/services'

import type {
  EmailSendRequest,
  SignInByCodeRequest,
  SignInByCodeResponse,
  RegisterRequest,
  User,
  UpdateRequest,
} from './types'

export const AuthService = {
  async register(body: RegisterRequest) {
    const { data } = await publicHttp.post<SignInByCodeResponse>(
      '/email-auth/register',
      body
    )

    return data
  },

  async requestAuthorizationEmail(body: EmailSendRequest) {
    await publicHttp.post('/email-auth/send', body)
  },

  async requestAuthorizationByCode(body: SignInByCodeRequest) {
    const { data } = await publicHttp.post<SignInByCodeResponse>(
      '/email-auth/login',
      body
    )

    return data
  },

  async update(body: UpdateRequest) {
    const { data } = await authHttp.post<User>('/user', body)

    return data
  },
}
