import clsx from 'clsx'
import { InputHTMLAttributes, forwardRef } from 'react'

import styles from './Input.module.css'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean
}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, error, ...props }, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        className={clsx(styles.root, error && styles.error, className)}
      />
    )
  }
)

Input.displayName = 'Input'
